import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import mapoImg from "../../assets/w.png";
import "./footer.css";
import { useEffect, useState } from "react";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour to handle year change during page runtime

    // Cleanup function to clear interval
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="footer-area text-center footer-padding bg-222">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-20px">
              <img alt="img" src={mapoImg} />
            </div>
            <div className="col-md-12">
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/MapoSoftwareSolutions"
                  className="color-bbb radius-50 mr-5px mb-10px color-fff-hvr bg-orange-hvr transition-3"
                  target="_blank"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://twitter.com/maposouthafrica?lang=en"
                  className="color-bbb radius-50 mr-5px mb-10px color-fff-hvr bg-orange-hvr transition-3"
                  target="_blank"
                >
                  <FaTwitter />
                </a>

                <a
                  href="https://za.linkedin.com/company/mapo-software-solutions---pty-ltd-"
                  className="color-bbb radius-50 mr-5px mb-10px color-fff-hvr bg-orange-hvr transition-3"
                  target="_blank"
                >
                  <FaLinkedin />
                </a>

                <a
                  href="https://www.youtube.com/@mapointernational9212"
                  className="color-bbb radius-50 mr-5px mb-10px color-fff-hvr bg-orange-hvr transition-3"
                  target="_blank"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <p className="mt-10px mb-0px color-eee">
                © {currentYear}
                <strong className="color-orange"> MAPO International</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
