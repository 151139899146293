import React from "react";
import { Link } from "react-scroll"; // Assuming you're using react-scroll for smooth scrolling
import { useNavigate } from "react-router-dom";
import w from "../../../assets/w.png";

const Navigation = () => {
  const navigate = useNavigate();

  return (
    <nav className={"nav active"}>
      <Link to="/" className="logo" smooth={true} duration={250}>
        <img src={w} alt="logo.png" />
      </Link>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="nav-icon"></span>
      </label>
      <ul className="menu">
        <li>
          <a smooth={true} duration={250}>
            <Link
              onClick={() => navigate("/")}
              to="About"
              smooth={true}
              duration={250}
            >
              Home
            </Link>
          </a>
        </li>
        <li>
          <a smooth={true} duration={250}>
            <Link
              onClick={() => navigate("/")}
              to="About"
              smooth={true}
              duration={250}
            >
              About
            </Link>
          </a>
        </li>
        <li>
          <Link
            onClick={() => navigate("/")}
            to="Services"
            smooth={true}
            duration={250}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            onClick={() => navigate("/")}
            to="Industries"
            smooth={true}
            duration={250}
          >
            Industries
          </Link>
        </li>
        <li>
          <Link
            onClick={() => navigate("/")}
            to="Solutions"
            smooth={true}
            duration={250}
          >
            Solutions
          </Link>
        </li>
        <li>
          <Link
            onClick={() => navigate("/")}
            to="International"
            smooth={true}
            duration={250}
          >
            International
          </Link>
        </li>
        <li>
          <Link
            onClick={() => navigate("/")}
            to="Contact"
            smooth={true}
            duration={250}
          >
            Contact us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
