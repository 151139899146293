import flags from "../../assets/flags.png"

function FlagsnPartnership() {
  return (
    <>
      <section id="International" class="about-area sec-padding" data-scroll-index="6">
        <div class="container MoveAbit">
          <h1 class="title-h p-relative">
            <span class="fw-200">MAPO</span> International
            <span class="line p-absolute bg-orange"></span>
          </h1>
          <p class="title-p">
            MAPO International Co Ltd is looking for a distribution partner in
            countries where OEMs has established markets, a company that share
            the same goals and the vision of MAPO being the market leader in
            market research software and data source to industry. A partnering
            company that is as excited about the solution and who will give more
            than what is expected to achieve our goals. A partner who would be
            active in growing the MAPO brand to its full potential by adding
            value using their business knowledge and client database in the
            local market.
          </p>
          <div class="row">
            <div class="col-md-6"
            >
              <div
                class="about-image mb-50px wow fadeInLeft"
                data-wow-delay="0.4s"
              >
                <img src={flags} alt="about" />
              </div>
            </div>
            <div class="col-md-6"
            >
              <div class="about-text wow fadeInRight" 
              data-wow-delay="0.7s">
                <h3 class="fw-600 mb-20px">
                  <span class="fw-200"> Distribution </span> Partnership
                </h3>
                <strong
                  style={{
                    borderBottom: " #00000059",
                    borderBottom: "solid",
                    borderBottomWidth: "1px",
                    fontWeight: "600",
                  }}
                >
                  Are the industries mentioned, areas I am familiar with?
                </strong>
                <p class="mb-20px">
                  If the industries mentioned in this document are unknown to
                  you and you would like to use Mapo to enter into these markets
                  with your own product, please don’t waste our time.
                </p>
                <strong
                  style={{
                    borderBottom: " #00000059",
                    borderBottom: "solid",
                    borderBottomWidth: "1px",
                    fontWeight: "600",
                  }}
                >
                  Do I have skills that will grow the Mapo brand?
                </strong>
                <p class="mb-20px">
                  Mapo doesn’t need to partner in software development. We do
                  wish to partner with a company who has a client base in the
                  mentioned industries, we would like a partner with strong
                  sales and marketing skills. Or, tell us what we are missing
                  and what your company has to offer.
                </p>
                <strong
                  style={{
                    borderBottom: " #00000059",
                    borderBottom: "solid",
                    borderBottomWidth: "1px",
                    fontWeight: "600",
                  }}
                >
                  Do I have the finance available to obtain the distribution
                  rights?
                </strong>
                <p>
                  Although the Mapo software has the potential in generating a
                  lot of revenue that will make both parties very wealthy, we
                  will need a considerable financial commitment for share
                  options in the distribution rights of the Mapo software.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FlagsnPartnership;
