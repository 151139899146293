import React from "react";
import { Carousel, Card } from "react-bootstrap";
import "./industries.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Industries() {
  const topDealsItems = [
    {
      title: "Passenger Vehicle",
      details:
        "Whether your Family, Sport or Passenger Vehicle purchase is an investment for your business or a purchase for luxury and relaxation, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Passenger.jpg"),
    },
    {
      title: "Commercial Vehicles",
      details:
        "Your Commercial Vehicle purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Commercial.jpg"),
    },
    {
      title: "Mining Equipment",
      details:
        "Your Mining Moving Equipment purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Mining.jpg"),
    },
    {
      title: "Motorcycles & ATVs",
      details:
        "Whether your Motorcycle or All-Terrain Vehicle purchase is an investment for your business or a purchase for luxury and relaxation, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/ATV.jpg"),
    },
    {
      title: "Earth Moving Equipment",
      details:
        "Your Earth Moving Equipment purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Earth_moving.jpg"),
    },
    {
      title: "Factory & Warehousing",
      details:
        "Your Factory & Warehousing Equipment purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Warehousing.jpg"),
    },
    {
      title: "Aerospace & Aircrafts",
      details:
        "Whether your Aircraft and Aerial Equipment purchase is an investment for your business or a purchase for luxury and relaxation, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Air.jpg"),
    },
    {
      title: "Agricultural Vehicles & Equipment",
      details:
        "Your Agricultural Equipment purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Agri.jpg"),
    },
    {
      title: "Special Vehicles & Equipment",
      details:
        "If your Special or Purpose Build Vehicle purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Special.jpg"),
    },
    {
      title: "Military Vehicles & Equipment",
      details:
        "If your Military Vehicles & Equipment purchase is an investment for your business, you are constantly evaluating to determine which purchase is the better value for you.",
      Img: require("../../assets/welcomeSlider/Military.jpg"),
    },
  ];
  // Chunk array into groups of 4 items
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunks = chunkArray(topDealsItems, 4);

  return (
    <section
      id="Industries"
      className="services-area blog-area sec-padding text-center"
      data-scroll-index="4"
    >
      <div className="container MoveAbit">
        <h1 className="title-h p-relative">
          <span className="fw-200">Our Focus on</span> Industries
          <span className="line p-absolute bg-orange"></span>
        </h1>
        <p className="title-p">
          The Mapo software is an Internet based data warehouse and market
          research tool in the Automotive and Commercial Vehicles Industries,
          and is now also available for Construction-, Agricultural-, Marine-,
          Forestry, Earth Moving- and Mining equipment and vehicles.
        </p>
      </div>

      <div className="container">
        <Carousel interval={null} id="industries-carousel">
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-around">
                {chunk.map((item, subIndex) => (
                  <Card key={subIndex} style={{ width: "18rem" }}>
                    <Card.Img variant="top" src={item.Img} />
                    <Card.Body>
                      <Card.Text>
                        <p className="color-999 mb-10px">{item.details}</p>
                      </Card.Text>
                      <Card.Title>
                        <h5 className="fw-600 mb-20px">
                          <a className="color-333 color-orange-hvr">{item.title}</a>
                        </h5>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
    
        </Carousel>
      </div>
    </section>
  );
}

export default Industries;