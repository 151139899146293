import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { SalesSolution } from "./pages/SolutionsPages/salesSolution";
import { FleetSolution } from "./pages/SolutionsPages/fleetSolution";
import { SmartApp } from "./pages/SolutionsPages/SmartApp";
import { OemMarketResearch } from "./pages/SolutionsPages/OemMarketReserch";
import { InsureAnalysis } from "./pages/SolutionsPages/InsureAnalysis";
import { DatahubSolution } from "./pages/SolutionsPages/DatahubSolution";
import { ApiFeedSolution } from "./pages/SolutionsPages/apiSolution";
import { CatalogSolution } from "./pages/SolutionsPages/CatalogSolution";
import News from "./components/news/news";
import DataDeletionRequest from "./pages/UserDeleteRequest/RequestDelete";

const AppWrapper = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/SalesSolution" element={<SalesSolution />} />
        <Route path="/FleetSolution" element={<FleetSolution />} />
        <Route path="/SmartApp" element={<SmartApp />} />
        <Route path="/OemMarketResearch" element={<OemMarketResearch />} />
        <Route path="/InsureAnalysis" element={<InsureAnalysis />} />
        <Route path="/DatahubSolution" element={<DatahubSolution />} />
        <Route path="/ApiFeedSolution" element={<ApiFeedSolution />} />
        <Route path="/CatalogSolution" element={<CatalogSolution />} />
        <Route path="/News" element={<News />} />
        <Route path="/DataDeleteRequest" element={<DataDeletionRequest/>} />
      </Routes>
    </Router>
  );
};

export default AppWrapper;
