import React from "react";
import "./load.css";
import 'bootstrap/dist/css/bootstrap.css';


function load() {
  return (
    <div class="load-wrapp">
      <div class="wrap">
        <ul class="dots-box">
          <li class="dot">
            <span></span>
          </li>
          <li class="dot">
            <span></span>
          </li>
          <li class="dot">
            <span></span>
          </li>
          <li class="dot">
            <span></span>
          </li>
          <li class="dot">
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default load;

