import React from "react";
import { Carousel, Card } from "react-bootstrap";
import "./services.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Services() {
  const topDealsItems = [
    {
      title: "Total Cost of Ownership",
      details: "Detailed TCO calculated down to 4 (0,0001) decimals.",
      Img: require("../../assets/services/total_cost_of_ownership.png"),
    },
    {
      title: "Parts Basket",
      details:
        "Unique back-bone structure where parts are listed and categorized by model.",
      Img: require("../../assets/services/parts_basket.png"),
    },
    {
      title: "Service Cost",
      details: "Detailed service schedule available in milli-seconds.",
      Img: require("../../assets/services/service_cost.png"),
    },
    {
      title: "Residual-Value",
      details:
        "No estimates or assumptions, calculated on 3x Levels of a specific model variant.",
      Img: require("../../assets/services/residual_value.png"),
    },
    {
      title: "Maintenance Cost",
      details: "Detailed maintenance schedule available in milli-seconds.",
      Img: require("../../assets/services/maintenance_cost.png"),
    },
    // Add more items as needed
  ];

  // Helper function to chunk the array into groups of four
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunks = chunkArray(topDealsItems, 4);

  return (
    <section
      id="Services"
      className="services-area blog-area sec-padding text-center"
      data-scroll-index="3"
    >
      <div className="container MoveAbit">
        <h1 className="title-h p-relative">
          <span className="fw-200">Our</span> Services
          <span className="line p-absolute bg-orange"></span>
        </h1>
        <p className="title-p">
          The Mapo software is designed as a market research tool for concept-,
          current- and discontinued vehicles and equipment in various
          industries. We offer data in the areas of their parts pricing,
          service- and maintenance costs, total cost of operation and much more.
        </p>
      </div>

      <div id="menu" className="container">
        <Carousel interval={null} id="services-carousel">
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-around">
                {chunk.map((item, subIndex) => (
                  <Card key={subIndex} style={{ width: "18rem" }}>
                    <Card.Img variant="top" src={item.Img} />
                    <Card.Body>
                      <Card.Text>
                        <p className="color-999 mb-10px">{item.details}</p>
                      </Card.Text>
                      <Card.Title>
                        <h5 className="fw-600 mb-20px">
                          <a className="color-333 color-orange-hvr">{item.title}</a>
                        </h5>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
          {/* Custom Carousel Controls */}
          {/* <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#services-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#services-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon"></span>
            <span className="visually-hidden">Next</span>
          </button> */}
        </Carousel>
      </div>
    </section>
  );
}

export default Services;
