import React from "react";
import MobileAppPromo from "../../assets/Solutions_Mobile_App.png";
import "./style.css";

export const MobilePromoImg = () => {
  const handleClick = () => {
    const newWindow = window.open("https://mobile.mapo-int.com", "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Popup blocked or unable to open window.");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        onClick={handleClick}
        src={MobileAppPromo}
        className="app-promo"
        alt="Mobile App Promo"
        style={{ width: "100%", height: "auto" }}
      />

      <ClickableArea
        width={180}
        height={35}
        // backgroundColor="rgba(255, 0, 0, 0.5)"
        onClick={handleClick}
      />
    </div>
  );
};

function ClickableArea({ width, height, backgroundColor, onClick }) {
  const style = {
    position: "absolute",
    width: width,
    height: height,
    backgroundColor: backgroundColor,
    cursor: "pointer",
  };

  return <div className="clickable-one" style={style} onClick={onClick}></div>;
}
