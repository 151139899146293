import React, { useState, useEffect } from "react";
import ApiFeed from "../../assets/solutions/api_.png";
import Catalogsolutions from "../../assets/solutions/catalog_.png";
import FleetReserch from "../../assets/solutions/fleet_.png";
import InsureAnalysis from "../../assets/solutions/insure.png";
import OemData from "../../assets/solutions/oem_data.png";
import OemMarket from "../../assets/solutions/oem_.png";
import SalesSolution from "../../assets/solutions/sales_.png";
import SmartApp from "../../assets/solutions/smart.png";
import "./solutions.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Carousel, Card } from "react-bootstrap";

function Solutions() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  // Update isMobile state on resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Solution data
  const topDealsItems = [
    {
      img: SalesSolution,
      link: "/SalesSolution",
      shortDesc: "Know your product and use the information",
      title: "SALES",
      desc: "Sales consultants can only perform if they have the tool required to close the deal.",
    },

    {
      img: ApiFeed,
      link: "/ApiFeedSolution",
      shortDesc: "Feed your system",
      title: "API",
      desc: "It’s become imperative to Insurance, Fleet Management Companies, and Fleet Owners of any fleet from passenger vehicles",
    },
    {
      img: SmartApp,
      link: "/SmartApp",
      shortDesc: "Vehicle information on the GO",
      title: "MOBILE",
      desc: "MAPO’s application, we have created a simple to use app for all your vehicle needs. MAPO offers up-to-date prices and schedules on thousands of model's",
    },
    {
      img: OemMarket,
      link: "/OemMarketResearch",
      shortDesc: "Know your strengths and weaknesses",
      title: "OEM",
      desc: "Original Equipment Manufacturers constantly require market research to offer the best products and service in a very competitive market.",
    },
    {
      img: FleetReserch,
      link: "/FleetSolution",
      shortDesc:
        "Manage and Plan your fleet usage and costs accurately and effectively",
      title: "FLEET",
      desc: "Still under Development",
    },
    {
      img: OemData,
      link: "/DatahubSolution",
      shortDesc: "Effortlessly channel data to your clients",
      title: "DataHub",
      desc: "The world’s most valuable resource is no longer oil or gold, it’s data!",
    },
    {
      img: Catalogsolutions,
      link: "/CatalogSolution",
      shortDesc: "Structured access to your parts",
      title: "CATALOG",
      desc: "Still under Development",
    },
    {
      img: InsureAnalysis,
      link: "/InsureAnalysis",
      shortDesc: "Insurance Claims Analysis of Vehicle Damage Assessment",
      title: "INSURE",
      desc: "As a leader in software solutions we at MAPO have made an incredible impact on how Original Equipment Manufacturers conduct market research on their vehicles and equipment",
    },
  ];

  // Helper function to chunk the array into groups of four
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunks = chunkArray(topDealsItems, 4);

  return (
    <>
      <section
        id="Solutions"
        className="services-area blog-area sec-padding text-center"
        data-scroll-index="5"
      >
        <div className="container MoveAbit">
          <h1 className="title-h p-relative">
            <span className="fw-200">Our software</span> Solutions
            <span className="line p-absolute bg-orange"></span>
          </h1>
          <p className="title-p">
            We offer services that not only address and fix Industry
            requirements, but provides cost saving through accurate asset
            identification, streamlines internal processes and ensures that
            OEM’s are accurately represented in the marketplace, these
            innovative solutions benefit the OEM, Insurance, Fleet and Consumer
            as the end user in the value chain.
          </p>
        </div>

        <div className="container">
          {isMobile ? (
            <Carousel interval={null}>
              {topDealsItems.map((solution, index) => (
                <Carousel.Item key={index}>
                  <div className="d-flex justify-content-around">
                    <Card
                      key={index}
                      className="cardSlid mb-3"
                      style={{ width: "18rem" }}
                      onClick={() => navigate(solution.link)}
                    >
                      <Card.Img  variant="top" src={solution.img} />
                      {/* <Card.Img
                        variant="top"
                        src={solution.img}
                        alt="Image description"
                      /> */}
                      <Card.Body>
                        <Card.Text>
                          <p className="color-999 mb-10px">
                            {solution.shortDesc}
                          </p>
                        </Card.Text>
                        <Card.Title>
                          <h5 className="Solution-title">
                            <span className="fw-200">{solution.title}</span>
                          </h5>
                        </Card.Title>
                        <Card.Text>
                          <p className="color-999 mb-10px">{solution.desc}</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Carousel interval={null}>
              {chunks.map((chunk, index) => (
                <Carousel.Item key={index}>
                  <div className="d-flex justify-content-around">
                    {chunk.map((solution, subIndex) => (
                      <Card
                        key={subIndex}
                        className="cardSlid"
                        style={{ width: "18rem" }}
                        onClick={() => navigate(solution.link)}
                      >
                        {/* <Card.Img
                          className="img-fluid d-block mx-auto"
                          style={{ height: "40%", width: "40%" }}
                          variant="top"
                          src={solution.img}
                          alt="Image description"
                        /> */}
                        <Card.Img  variant="top" src={solution.img} />
                        <Card.Body>
                          <Card.Text>
                            <p className="color-999 mb-10px">
                              {solution.shortDesc}
                            </p>
                          </Card.Text>
                          <Card.Title>
                            <h5 className="Solution-title">
                              <span className="fw-200">{solution.title}</span>
                            </h5>
                          </Card.Title>
                          <Card.Text>
                            <p className="color-999 mb-10px">{solution.desc}</p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </section>
    </>
  );
}

export default Solutions;
