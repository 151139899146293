import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import NavBar from "../components/nav/Navbar";
import Load from "../components/loadingWrap/load-wrap";
import About from "../components/about/about";
import Services from "../components/services/services";
import Industries from "../components/industries/industries";
import Solutions from "../components/solutions/solutions";
import Partnership from "../components/MapoFlagsPartnership/index";
import Contact from "../components/contacts/contact";
import Footer from "../components/footer/footer";
import "./home.css";
import "../Animation.css";
import "../lightbox.css";
import Typewriter from "typewriter-effect";
import "../GlobalStyles.css";
import oem from "../assets/features/oem.png";
import dealer from "../assets/features/dealer.png";
import financial from "../assets/features/financial.png";
import fleet from "../assets/features/fleet.png";
import consumer from "../assets/features/consumer.png";
import insurance from "../assets/features/insurance.png";
import { FaWhatsapp } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import MobilePromoImg from "../assets/Solutions_Mobile_App.png";
import News from "../components/news/news";

export const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 0); //add seconds remember
  }, []);

  const handleClick = () => {
    const newWindow = window.open("https://mobile.mapo-int.com", "_blank");
    if (newWindow) {
      newWindow.focus(); // Attempt to focus the new window/tab
    } else {
      console.error("Popup blocked or unable to open window.");
      // You can provide alternative instructions here for users to manually open the link
    }
  };
  return (
    <>
      {loading ? <Load /> : <></>}

      <NavBar />

      <section id="/" class="welcome-slider  text-center">
        <div //class="container-fluid "
        >
          <div //class="imageSlide1"
          >
            {isMobile ? (
              <div class="">
                <div class="imageSlide1"></div>{" "}
              </div>
            ) : (
              <div class="slider">
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
              </div>
            )}

            <div>
              <div class="overlay-bg-65">
                <div class="flex-center">
                  <div class="">
                    <p class="color-fff mb-20px">
                      MAPO offers subscribers and application users access to
                      accurate Model Identification and data in Parts Pricing,
                      Service Costs, Maintenance Costs and Total Cost of
                      Ownership, Residual Values and much, much more.
                    </p>
                    <img
                      onClick={handleClick}
                      src={MobilePromoImg}
                      className="home-promo-img"
                    />
                    <div
                      className="click-promo-img"
                      onClick={handleClick}
                    ></div>

                    <h1 class="mb-20px color-fff fw-100 cd-headline clip"></h1>
                    <h1 class="mb-20px color-fff fw-100 cd-headline clip">
                      <span class="cd-words-wrapper">
                        <b class="is-visible color-orange text-outline-cust fw-700 typeText">
                          <Typewriter
                            className="typeText"
                            options={{
                              strings: [
                                "Mining equipment",
                                "Passenger Vehicle",
                                "Commercial Vehicles",
                                "Motorcycles & ATVs",
                                "Earth Moving Equipment",
                                "Factory & Warehousing",
                                "Marine & Water Equipment",
                                "Forestry Vehicles & Equipment",
                                "Aerospace & Aircrafts",
                                "Agricultural Vehicles & Equipment",
                                "Special Vehicles & Equipment",
                                "Military Vehicles & Equipment",
                              ],
                              autoStart: true,
                              loop: true,
                            }}
                          />
                        </b>
                      </span>
                    </h1>
                    <h4 class="color-eee fw-300 mb-20px">
                      Make the informed choice, Register or log in now.
                    </h4>
                    <a
                      class="main-btn btn-1 mt-30px mr-5px ml-5px"
                      href="https://za-apihub.mapo-int.com/Identity/Account/RegisterSolutionSelection"
                    >
                      Register
                    </a>
                    <a
                      class="main-btn btn-2 mt-10px ml-5px mr-5px"
                      href="https://za-apihub.mapo-int.com/Identity/Account/Login "
                      data-toggle="modal"
                      data-target="#loginModel"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features-area sec-padding text-center">
        <div class="container MoveAbit">
          <h1 class="title-h p-relative">
            <span class="fw-200"> Welcome to</span> MAPO
            <span class="line p-absolute bg-orange"></span>
          </h1>
          <p class="title-p">
            As a leader in software solutions we at MAPO have made an incredible
            impact on how Original Equpment Manufacturers conduct market
            research on their vehicles and equipment.
          </p>
          <div class="row">
            <div class="col-md-2">
              <div class="mb-25px mt-25px wow fadeInUp" data-wow-delay="0.3s">
                <img src={oem} class="fs-40 mb-20px " />
                <h5 class="fw-600">OEM</h5>
                <p>
                  Internet-Based market research tool for Orignal Equipment
                  Manufacturers on all types of vehicles and equipment in
                  various industries
                </p>
                <a href="#0">
                  <i class="fa fa-angle-right fs-20 bg-orange bg-333-hvr color-fff radius-50 mt-10px transition-4"></i>
                </a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-25px mt-25px wow fadeInUp" data-wow-delay="0.5s">
                <img src={insurance} class="fs-40 mb-20px " />
                <h5 class="fw-600">Insurance</h5>
                <p>
                  Fast and accurate Collision parts basket info is crucial from
                  planning stage monitoring and claims finalisation stage.
                </p>
                <a href="#0">
                  <i class="fa fa-angle-right fs-20 bg-orange bg-333-hvr color-fff radius-50 mt-10px transition-4"></i>
                </a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-25px mt-25px wow fadeInUp" data-wow-delay="0.7s">
                <img src={financial} class="fs-40 mb-20px " />
                <h5 class="fw-600">Financial</h5>
                <p>
                  All efforts in staying abreast of costs can prove fruitless,
                  if they fail at identifying the asset.
                </p>
                <a href="#0">
                  <i class="fa fa-angle-right fs-20 bg-orange bg-333-hvr color-fff radius-50 mt-10px transition-4"></i>
                </a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-25px mt-25px wow fadeInUp" data-wow-delay="0.9s">
                <img src={consumer} class="fs-40 mb-20px " />
                <h5 class="fw-600">Consumer</h5>
                <p>
                  With transparency and availability of accurate data, consumers
                  are empowered to make informed decisions.
                </p>
                <a href="#0">
                  <i class="fa fa-angle-right fs-20 bg-orange bg-333-hvr color-fff radius-50 mt-10px transition-4"></i>
                </a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-25px mt-25px wow fadeInUp" data-wow-delay="0.9s">
                <img src={fleet} class="fs-40 mb-20px " />
                <h5 class="fw-600">Fleet</h5>
                <p>
                  Now is the time they require more support and data from OEMs,
                  channel data to accurately calculate and monitor the costs of
                  your services to your clients.{" "}
                </p>
                <a href="#0">
                  <i class="fa fa-angle-right fs-20 bg-orange bg-333-hvr color-fff radius-50 mt-10px transition-4"></i>
                </a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-25px mt-25px wow fadeInUp" data-wow-delay="0.9s">
                <img src={dealer} class="fs-40 mb-20px " />
                <h5 class="fw-600">Dealers</h5>
                <p>
                  Sales consultants can only perform if they have the required
                  tools to close the deal.
                </p>
                <a href="#0">
                  <i class="fa fa-angle-right fs-20 bg-orange bg-333-hvr color-fff radius-50 mt-10px transition-4"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <News /> */}

      <About />

      <Services />

      <Industries />

      <Solutions />

      <Partnership />

      <Contact />

      <a
        href="https://wa.me/27646826775"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className="whatsapp-icon" />
      </a>

      <Footer />
    </>
  );
};
