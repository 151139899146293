import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const DataDeletionRequest = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage(""); // Clear previous error message

    try {
      const response = await fetch(
        `https://za-api.mapo-int.com/api/UserData/DeleteUserData?username=${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // If response is successful
        setSuccessMessage("Your data deletion request was successful.");
      } else {
        // If response is not successful, show error message
        setErrorMessage("There was an error processing your request. Please try again.");
      }
    } catch (error) {
      // Handle network errors
      setErrorMessage("Network error. Please check your connection and try again.");
    } finally {
      setLoading(false);
      setEmail("");
    }
  };

  return (
    <section
      id="Contact"
      className="contact-area sec-padding"
      data-scroll-index="7"
    >
      <div className="container MoveAbit">
        <h1 className="title-h p-relative">
          <span className="fw-200">Request </span> Data Deletion
          <span className="line p-absolute bg-orange"></span>
        </h1>
        <br />
        <p className="title-p">
          If you would like to request the deletion of your personal data,
          please enter your username below:
        </p>
 
    
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Username</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                value={email}
                placeholder="Your Username"
                className="form-control"
                required
                disabled={loading}
              />

              <br />
              <input
                type="submit"
                className="btn btn-primary w-100"
                value={loading ? "Submitting..." : "Submit"}
                disabled={loading}
              />
            </form>

            {successMessage && (
              <div
                className="alert alert-success mt-4 text-center"
                role="alert"
              >
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div
                className="alert alert-danger mt-4 text-center"
                role="alert"
              >
                {errorMessage}
              </div>
            )}
          </div> 
          <br/>
         
        </div>
        <br/>
        <p className="title-p">
          <b>**Please note:**</b> By submitting this request,we will permanently delete your username and password from our database.
          This action cannot be undone. We retain your data for a maximum of 30 days before permanent deletion.
        </p>
      </div>
      
    </section>
  );
};

export default DataDeletionRequest;
