import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import newsData from "./News.json";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel, Button } from "react-bootstrap";
import { FiShare2 } from "react-icons/fi"; // Import share icon from React Icons or another icon library

const parseDate = (dateString) => {
  const [day, month, year] = dateString.split(" ");
  const monthIndex = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  }[month];
  return new Date(year, monthIndex, parseInt(day, 10));
};

// Sort newsData by date in descending order
const sortedNewsData = [...newsData].sort(
  (a, b) => parseDate(b.date) - parseDate(a.date)
);

const News = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const titleParam = queryParams.get("title");

  useEffect(() => {
    // Find the index of the news article with matching titleParam
    const index = sortedNewsData.findIndex((news) => news.title === titleParam);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [titleParam]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const handleNext = () => {
    const nextIndex = (activeIndex + 1) % sortedNewsData.length;
    setActiveIndex(nextIndex);
  };

  const handleShare = () => {
    const shareData = {
      title: sortedNewsData[activeIndex].title,
      text: sortedNewsData[activeIndex].title,
      url: window.location.href,
    };
    navigator.share(shareData);
  };

  const renderHTML = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    tempElement.querySelectorAll("a").forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return { __html: tempElement.innerHTML };
  };

  return (
    <>
      <section id="News">
        <div className="container about-area sec-padding">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="title-h p-relative">
              <span className="fw-200">Latest car</span> News
              <span className="line p-absolute bg-orange"></span>
            </h1>
            <Button variant="link" onClick={handleShare}>
              <FiShare2 size={24} />
            </Button>
          </div>
          <p>
            <strong>{sortedNewsData[activeIndex].date}</strong>
          </p>

          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            controls={true}
            indicators={false}
            interval={null}
          >
            {sortedNewsData.map((news, index) => (
              <Carousel.Item key={index}>
                <div className="container-fluid p-0">
                  <div className="row no-gutters">
                    <div className="col-12 position-relative">
                      <img
                        src={news.image}
                        className="img-fluid News-img"
                        alt="Background"
                      />
                      <div className="News-overlay"></div>
                      <div className="content text-white position-absolute d-flex justify-content-start align-items-center">
                        <h2 className="mb-3 text-white p-4">{news.title}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="overlay-parent d-flex justify-content-center align-items-center">
                    <div className="overlay-container col-12 col-md-8 col-sm-8 col-lg-8 bg-white p-3 text-center">
                      <p
                        dangerouslySetInnerHTML={renderHTML(
                          news.additionalText
                        )}
                      ></p>
                      <Button onClick={handleNext} variant="link">
                        Read Next Article
                      </Button>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
};

export default News;
