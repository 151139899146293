import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FaBars } from "react-icons/fa";
import w from "../../assets/w.png";
import b from "../../assets/b.png";
import "./nav.css";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

function Navbar() {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  //className={colorChange ? 'navbar colorChange' : 'navbar'}
  const [nav, setnav] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  // setTimeout(() => setShow(true), 1500);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setnav(true);
    } else {
      setnav(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <nav className={nav ? "nav active" : "nav"}>
        <Link to="/" className="logo" smooth={true} duration={250}>
          <img src={w} alt="logo.png" />
        </Link>
        <input
          onClick={() => setShow(true)}
          className="menu-btn"
          type="checkbox"
          id="menu-btn"
        />
        <label className="menu-icon" for="menu-btn">
          <span className="nav-icon"></span>
        </label>

        {isMobile ? (
          <>
            {show && (
              <ul className="menu">
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="/"
                    smooth={true}
                    duration={250}
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="About"
                    smooth={true}
                    duration={250}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="News"
                    smooth={true}
                    duration={250}
                  >
                    News
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="Services"
                    smooth={true}
                    duration={250}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="Industries"
                    smooth={true}
                    duration={250}
                  >
                    Industries
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="Solutions"
                    smooth={true}
                    duration={250}
                  >
                    Solutions
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="International"
                    smooth={true}
                    duration={250}
                  >
                    International
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShow(!show)}
                    to="Contact"
                    smooth={true}
                    duration={250}
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            )}
          </>
        ) : (
          <ul className="menu">
            <li>
              <Link to="/" smooth={true} duration={250}>
                Home
              </Link>
            </li>
            <li>
              <Link to="About" smooth={true} duration={250}>
                About
              </Link>
            </li>
            <li>
              <Link to="News" smooth={true} duration={250}>
                News
              </Link>
            </li>
            <li>
              <Link to="Services" smooth={true} duration={250}>
                Services
              </Link>
            </li>
            <li>
              <Link to="Industries" smooth={true} duration={250}>
                Industries
              </Link>
            </li>
            <li>
              <Link to="Solutions" smooth={true} duration={250}>
                Solutions
              </Link>
            </li>
            <li>
              <Link to="International" smooth={true} duration={250}>
                International
              </Link>
            </li>
            <li>
              <Link to="Contact" smooth={true} duration={250}>
                Contact us
              </Link>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
}
export default Navbar;
