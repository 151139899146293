import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  FaBars,
  FaCheckCircle,
  FaFilePdf,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import w from "../../assets/w.png";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import "./Styles/salesSolution.css";
import "bootstrap/dist/css/bootstrap.css";
import InsureAnalysisImg from "../../assets/solutions/insure_analasys.png";
import Footer from "../../components/footer/footer";
import InsurancePdf from "../../Pdf/insurance-solutions.pdf";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Navigation from "./NavBar/Nav";
import { MobilePromoImg } from "../../components/Mobile-app-promo/appProm";

export const InsureAnalysis = () => {
  const [colorChange, setColorchange] = useState(false);
  const [show, setShow] = useState(false);
  const [MailSentShow, setMailSentShow] = useState(false);
  const [ContactShow, setContactShow] = useState(false);
  const [FailedContactShow, setFailedContactShow] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [errorMessageContact, setErrorMessageMobileContact] = useState("");
  const ContacthandleClose = () => setContactShow(false);
  const ContacthandleShow = () => setContactShow(true);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const FailedPhonehandleClose = () => setFailedContactShow(false);
  const FailedPhonehandleShow = () => setFailedContactShow(true);

  const EmailHandleClose = () => setMailSentShow(false);
  const EmailHandleShow = () => setMailSentShow(true);

  const [name, setName] = useState("");
  const [Contact, setContact] = useState("");

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = async (event) => {
    let value = event.target.value;

    if (value.startsWith("0")) {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      console.log("The country code", data);
      const countryCode = data.country_calling_code;
      value = countryCode + value.substring(1);
    }

    setContact(value);

    if (value.length <= 11) {
      const digitsLeft = 12 - value.length;
      setIsSubmitDisabled(true);
      setErrorMessageMobileContact(
        `Mobile number must be longer than 10 digits. You need ${digitsLeft} more digit${
          digitsLeft > 1 ? "s" : ""
        }.`
      );
    } else {
      setIsSubmitDisabled(false);
      setErrorMessageMobileContact("");
    }
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  //className={colorChange ? 'navbar colorChange' : 'navbar'}
  const [nav, setnav] = useState(false);
  const navigate = useNavigate();

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setnav(true);
    } else {
      setnav(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const [mailerState, setMailerState] = useState({
    email: "",
  });

  // function handleStateChange(e) {
  //   setMailerState((prevState) => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value,
  //   }));

  //   sessionStorage.setItem("UserEmail", mailerState.email);
  // }

  function handleStateChange(e) {
    const { name, value } = e.target;
    setMailerState((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };
      localStorage.setItem("UserEmail", newState.email);
      return newState;
    });
  }

  const submitEmail = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      to: [
        {
          email: mailerState.email,
          name: "MAPO International",
        },
      ],
      templateId: 24,
      headers: {
        "X-Mailin-custom":
          "custom_header_1:custom_value_1|custom_header_2:custom_value_2|custom_header_3:custom_value_3",
        charset: "iso-8859-1",
      },
    });
    console.log({ mailerState });
    const response = await fetch("https://api.brevo.com/v3/smtp/email", {
      method: "post",
      headers: {
        "api-key":
          "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",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((response) =>
        response.json().then((res) => ({ status: response.status, data: res }))
      )
      .then((apiResponse) => {
        console.log("e 1", apiResponse);
        if (apiResponse.status === 201) {
          ContacthandleShow();
        } else if (apiResponse.status === 400) {
          handleShow();
        }
      })
      .catch((error) => {
        console.error("Error: 2", error);
      })
      .then(() => {
        setMailerState({
          email: "",
        });
      });
  };

  const ContactUpdate = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      attributes: { FIRSTNAME: name, SMS: Contact },
      updateEnabled: true,

      //email: "giggslivwwe@gmail.com",
      email: localStorage.getItem("UserEmail"),
    });
    console.log({ mailerState });
    const response = await fetch("https://api.brevo.com/v3/contacts", {
      method: "post",
      headers: {
        "api-key":
          "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",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((response) =>
        response.json().then((res) => ({ status: response.status, data: res }))
      )
      .then((apiResponse) => {
        console.log("e 2", apiResponse);
        setErrorMessage(apiResponse.data.message);
        if (apiResponse.status === 201) {
          // EmailHandleShow();
          ContacthandleClose();
          EmailHandleShow();
        } else if (apiResponse.status === 400) {
          //handleShow();
          FailedPhonehandleShow();
        }
      })
      .catch((error) => {
        console.error("Error: for contact func", error);
      });
  };

  return (
    <>
      <Navigation />

      <div className=" Salesdiv">
        {/* <img alt="blog" src={InsureAnalysisImg} class="ModalIcon" />
        <span class="fw-400">
          <b>INSURE Analysis</b>
        </span> */}

        <div class="row align-items-start">
          <div class="col-auto no-padding">
            <img alt="blog" src={InsureAnalysisImg} class="solution-icon" />
          </div>
          <div class="col no-padding">
            <h1 class="title-solution p-relative">
              <span class="fw-200">INSURE </span> SOLUTION <br />
              <span class="fw-200 small slogan-font">
                Insure to make the accurate decision
              </span>
              <span class="line p-absolute bg-orange"></span>
            </h1>
          </div>
        </div>
        <br />
        <br />
        <div className="ModalSize">
          <p className="TextStyle">
            As a leader in software solutions we at MAPO have made an incredible
            impact on how Original Equipment Manufacturers conduct market
            research on their vehicles and equipment. The vast amount of parts
            data we have in MAPO has caught the attention of the Insurance
            Industry <br />
            <br />
            <span className="DisplayNoneMobile">
              Processing claims has a huge cost factor in manpower and the time
              it takes to analyses the claim. Researching the correct parts and
              pricing takes many hours/days and still causes incorrect results
              <br />
              MAPO developed a solution that accurately identifying a model,
              then monitor the part numbers and pricing of the parts claimed to
              see if it does fit the model with the VIN on the claim. Several
              other checks are also done that will ensure the correct decisions
              is made in repairing the vehicle. A complete claim analysis in
              seconds.
            </span>
          </p>
          <br />
          <br />
          <div className="formModalSales">
            <h1 class="title-h p-relative">
              <span class="fw-200">Get More </span> Information
              <span class="line p-absolute bg-orange"></span>
            </h1>
            <br />
            <form className="InputField" action="" onSubmit={submitEmail}>
              <label for="email">Email Address</label>
              <input
                type="text"
                placeholder="Email"
                onChange={handleStateChange}
                name="email"
                value={mailerState.email}
                required
                autocomplete="off"
              />

              <input
                type="submit"
                // onClick={SendEmailTemplat}
                value="Submit"
              />
            </form>

            <br />
            <br />
            {/* <a href={InsurancePdf} rel="noopener noreferrer" target="_blank">
              <FaFilePdf color="black" className="SolutionIcon" size={50} />
            </a> */}

            <a
              href="https://www.youtube.com/watch?v=X1zP0gpdGcQ"
              target="_blank"
            >
              <FaYoutube color="black" className="SolutionIcon" size={50} />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <Footer />
      </div>

      <a
        href="https://wa.me/27646826775"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className="whatsapp-icon" />
      </a>

      <Modal show={ContactShow} onHide={ContacthandleClose} closeButton>
        <Modal.Header>
          <Modal.Body
            style={{
              fontSize: "small",
              textAlign: "left",
              justifyContent: "left",
            }}
            className="w-100"
          >
            <FaCheckCircle
              size={54}
              color="green"
              className="justify-content-center d-flex w-100 text-center"
            />{" "}
            <br />
            We have sent an email with more information about SMART APP. Please
            check your inbox/spam for further details.
          </Modal.Body>
        </Modal.Header>

        <Modal.Title className="w-100 text-center">
          We would like to give you a call
        </Modal.Title>
        <Modal.Body>
          <form onSubmit={ContactUpdate}>
            <label for="email">Name</label>
            <input
              type="text"
              placeholder="Name"
              onChange={(e) => handleChange(e)}
              name="email"
              value={name}
              required
            />
            <label for="email">Contact Number</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                placeholder={`Enter mobile number`}
                onChange={(e) => handleEmailChange(e)}
                name="email"
                value={Contact}
                autoComplete="off"
                required
              />
            </div>
            {errorMessageContact && (
              <p style={{ fontSize: "small" }}>{errorMessageContact}</p>
            )}
            <input type="submit" value="Submit" disabled={isSubmitDisabled} />
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={FailedContactShow} onHide={FailedPhonehandleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage.replace("SMS", "Mobile Number")}</Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={FailedPhonehandleClose}>
            Ok
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Email Not sent</Modal.Title>
          {/* <FaCheck size={25} color="green" className="ModalIcons"/> */}
        </Modal.Header>
        <Modal.Body>
          Make sure the Email is correct:{" "}
          <b>{sessionStorage.getItem("UserEmail")} </b>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={MailSentShow} onHide={EmailHandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Email sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Email sent to: <b>{sessionStorage.getItem("UserEmail")} </b>
        </Modal.Body>
        <Modal.Footer>
          <span className="Spamfonts">
            Check in Promotions and junk/spam mail
          </span>
          {/* <Button variant="secondary" onClick={EmailHandleClose}>
            Ok
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
