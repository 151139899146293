import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import AboutImg from "../../assets/we_are.png";
import { FaCheck } from "react-icons/fa";

function about() {
  return (
    <section id="About" class="about-area sec-padding" data-scroll-index="2">
      <div class="container MoveAbit">
        <h1 class="title-h p-relative">
          <span class="fw-200">About</span> MAPO
          <span class="line p-absolute bg-orange"></span>
        </h1>
        <p class="title-p">
          MAPO accepts its responsibility in the various industries to remain
          innovative in developing cost saving solutions.{" "}
        </p>
        <div class="row">
          <div class="col-md-6">
            <div
              class="about-image mb-50px wow fadeInLeft"
              data-wow-delay="0.4s"
            >
              <img src={AboutImg} alt="about" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="about-text wow fadeInRight" data-wow-delay="0.7s">
            <span class="line p-absolute bg-orange"></span>
              <h3 class="fw-600 mb-20px">
                <span class="fw-200 "> We Are The </span> Vehicle and Equipment
                Data Bank
              </h3>
              

              <p class="mb-20px">
                Our innovative industry-first market research solution, provides
                OEMs detailed reports on strengths and weaknesses on their
                vehicles and equipment.{" "}
              </p>
              <div class="row">
                <div class="col-sm-6">
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Parts Price Benchmarking
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Price Elasticity
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Market Positioning
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Car-Park vs Sales Volumes
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Aftermarket Positioning
                  </p>
                </div>
              </div>
            </div>
            <div class="about-text wow fadeInRight" data-wow-delay="0.7s">
              <p class="mb-20px">
                Channeling accurate OEM data to Insurance, Fleet and Consumer
                brings peace-of-mind to OEMs having assurance that their
                products are correctly represented in all industries.{" "}
              </p>
              <div class="row">
                <div class="col-sm-6">
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Model Identification
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Parts Baskets
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Service Costs
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Maintenance Costs
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Total Cost of Ownership
                  </p>
                  <p class="mb-10px">
                    <FaCheck color="#df1d23" /> Residual-Value
                  </p>
                </div>
              </div>
              {/* <a href="#0" class="main-btn btn-3 mt-15px">
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default about;
