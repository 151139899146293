import "./contact.css";
import { useState,useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

function Contact() {
  const [show, setShow] = useState(false);
  const [MailSentShow, setMailSentShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const EmailHandleClose = () => setMailSentShow(false);
  const EmailHandleShow = () => setMailSentShow(true);

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    sessionStorage.setItem("UserEmail", mailerState.email);
  }

  const submitEmail = async (e) => {
    e.preventDefault();
    // Verify reCAPTCHA response
    // const recaptchaResponse = window.grecaptcha.getResponse();
    // if (!recaptchaResponse) {
    //   // reCAPTCHA not verified, display an error message or prevent submission
    //   console.log("reCAPTCHA not verified");
    //   return;
    // }
    let data = JSON.stringify({
      sender: {
        email: mailerState.email,
      },
      to: [{ email: "admin@mapo-int.com" }],
      replyTo: { email: "admin@mapo-int.com" },
      textContent:
        "Name of the Sender  " +
        mailerState.name +
        "      " +
        mailerState.message +
        " The User Contact number " +
        mailerState.number +
        " The User Email Address: " +
        mailerState.email,
      subject: mailerState.name + " From MAPO Website",
    });
    console.log({ mailerState });
    const response = await fetch("https://api.brevo.com/v3/smtp/email", {
      method: "post",
      headers: {
        "api-key":
          "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",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((response) =>
        response.json().then((res) => ({ status: response.status, data: res }))
      )
      .then((apiResponse) => {
        console.log("e 1", apiResponse);
        if (apiResponse.status === 201) {
          EmailHandleShow();
        } else if (apiResponse.status === 400) {
          handleShow();
        }
      })
      .catch((error) => {
        console.error("Error: 2", error);
      })
      .then(() => {
        setMailerState({
          email: "",
        });
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <section
        id="Contact"
        class="contact-area sec-padding"
        data-scroll-index="7"
      >
        <div class="container MoveAbit">
          <h1 class="title-h p-relative">
            <span class="fw-200">Contact </span> Us
            <span class="line p-absolute bg-orange"></span>
          </h1>
          <p class="title-p">
            How can we assist? Please feel free to contact us for your vehicle
            and equipment data requirements.
          </p>

          <div class="row">
            <div class="col-md-6">
              <form action="" onSubmit={submitEmail}>
                <label for="fname">Name</label>
                <input
                  type="text"
                  onChange={handleStateChange}
                  name="name"
                  value={mailerState.name}
                  placeholder="Your Name"
                  required
                />
                <label for="email">Email</label>
                <input
                  type="text"
                  onChange={handleStateChange}
                  name="email"
                  value={mailerState.email}
                  placeholder="Your Email Address"
                  required
                />

                <label for="email">Contact Number</label>
                <input
                  type="text"
                  onChange={handleStateChange}
                  name="number"
                  value={mailerState.number}
                  placeholder="Your Phone number"
                />

                <label for="subject">Subject</label>
                <textarea
                  onChange={handleStateChange}
                  name="message"
                  value={mailerState.message}
                  placeholder="Message"
                  style={{ height: "170px" }}
                ></textarea>

                {/* <div
                  className="g-recaptcha"
                  data-sitekey="6LdgB8opAAAAAJChAQDsC_ixfwGhnzcy_EBvr9Rh"
                /> */}
                 <br />
                <input type="submit" value="Submit" />
              </form>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Email Not sent</Modal.Title>
          {/* <FaCheck size={25} color="green" className="ModalIcons"/> */}
        </Modal.Header>
        <Modal.Body>
          Make sure the Email is correct:{" "}
          <b>{sessionStorage.getItem("UserEmail")} </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={MailSentShow} onHide={EmailHandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Email sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>Email sent</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={EmailHandleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Contact;
